/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "variables.less";
@import "mixins.less";

// Reset and dependencies
@import "normalize.less";
@import "print.less";
@import "glyphicons.less";

// Core CSS
@import "scaffolding.less";
@import "type.less";
@import "code.less";
@import "grid.less";
//@import "tables.less";
@import "forms.less";
@import "buttons.less";

// Components
@import "component-animations.less";
@import "dropdowns.less";
@import "button-groups.less";
@import "input-groups.less";
@import "navs.less";
@import "navbar.less";
@import "breadcrumbs.less";
@import "pagination.less";
@import "pager.less";
@import "labels.less";
@import "badges.less";
//@import "jumbotron.less";
@import "thumbnails.less";
@import "alerts.less";
//@import "progress-bars.less";
@import "media.less";
@import "list-group.less";
@import "panels.less";
@import "responsive-embed.less";
//@import "wells.less";
@import "close.less";

// Components w/ JavaScript
@import "modals.less";
//@import "tooltip.less";
@import "popovers.less";
//@import "carousel.less";

// Utility classes
@import "utilities.less";
@import "responsive-utilities.less";

#navmenu {
	border-color: transparent;
}
#main-header {

	

	&.affix {
		top: 0;
		display: block;
		width: 100%;
		padding-bottom: 0;
		background-color: @gray-lighter;
	    animation: slidein 1s;
	    -moz-animation: slidein 1s; /* Firefox */
	    -webkit-animation: slidein 1s; /* Safari and Chrome */
	    -o-animation: slidein 1s; /* Opera */
	    -webkit-box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.25);
		-moz-box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.25);
		box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.25);

		@keyframes fadein {
		    from {
		        opacity:0;
		    }
		    to {
		        opacity:1;
		    }
		}
		@-moz-keyframes fadein { /* Firefox */
		    from {
		        opacity:0;
		    }
		    to {
		        opacity:1;
		    }
		}
		@-webkit-keyframes fadein { /* Safari and Chrome */
		    from {
		        opacity:0;
		    }
		    to {
		        opacity:1;
		    }
		}
		@keyframes slidein {
		    from {
		        transform: translateY(-160px);
		    }
		    to {
		        transform: none;
		    }
		}
		@-moz-keyframes slidein { /* Firefox */
		     from {
		        transform: translateY(-160px);
		    }
		    to {
		        transform: none;
		    }
		}
		@-webkit-keyframes slidein { /* Safari and Chrome */
		     from {
		        transform: translateY(-160px);
		    }
		    to {
		        transform: none;
		    }
		}
	}
}

#main-nav {
	.affix & {
		margin-bottom: 0;
		margin-top: 0;
	}
}

#main-search {
	.searchString {
		display: inline-block;
		width: 80%;
		margin: 0;
		float: left;
	}
	.searchString__submit {
		display: inline-block;
		width: 20%;
		margin: 0;
		height: 49px;

		.affix & {
			background-color: darken(@btn-primary-bg, 10%);
		}
	}
	.claim {
		padding: @padding-base-vertical 0;
		.affix & {
			display: none;
		}
	}
	.affix & {
		padding: 0;
	}
	.suggerimenti {
		display: block;
		width: 100%;
		position: relative;
		z-index: 10;

		.close {
			font-size: 24px;
			font-weight: normal;
			opacity: .5;
		}

		ul {
			border: 1px solid @gray-lighter;
			border-top: none;
			width: 100%;
			display: block;
			margin: 0;
			background-color: @gray-lighter;
			position: absolute;
			padding-bottom: @padding-base-vertical;

			li {
					a {
						padding: @padding-base-vertical @padding-base-horizontal;
						display: block;
						text-transform: uppercase;
					}

				&.small {
					color: @gray;
					text-transform: uppercase;
					padding: @padding-base-vertical @padding-base-horizontal;
					font-weight: 700;
				}
			}

		}
	}
	&.consuggeriti {
		position: relative;
		&:after {
			content: ' ';
			width: 100vw;
			height: 100vh;
			background-color: rgba(255,255,255,0.7);
			position: fixed;
			z-index: 0;
		}
	}
}

.ur-elencobreve {
	margin-top: @padding-base-horizontal;
	min-height: 60vh;


	.ur-elencobreve__item {
		padding: @padding-base-vertical 0;
		border-bottom: 1px solid @gray-lighter;

		& > * {
			margin-top: @padding-base-vertical;
			margin-bottom: @padding-base-vertical;
		}

		h1 {
			.h3();

		}
		&__img {
			float: right;
			padding: 0;
			margin-left: @padding-base-vertical;
			margin-top: @line-height-computed;

			img {
				max-width: 100px;
				height: auto;
			}
		}
		&__telefono {
			.list-inline();
			li {
				padding-right: 0;
			}
			li:after {
				content: ' ,';
				font-weight: 700;
				color: lighten(@brand-primary, 20%);
				display: inherit;

			}
			& li:last-child {
				&:after {
				content: none;
				display: none;
				}
			}
		}
		&__incardinamenti {
			.list-unstyled();
			li {
				color: @gray-light;
				padding-right: 0;
			}
			li:first-child {
				color: @text-color;
				font-weight: 700;
			}
		}
	}
}
.download-vcard {
	position: inherit;
}
.scheda-persona {
	.container-fluid ();
	h1 {
		.h2();
	}
	h2 {
		.h4();
		word-break: break-all;
	}

	&__panel {
		margin-top: @padding-base-horizontal;
	}
}
.ur-paginaweb {
	word-break: break-all;

	@media (max-width: @screen-xs-max) { 
		display: block;
	    width: 75%;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	}
}
.ur-foto {
	position: relative;
	float: right;
	margin-left: 8px;
	max-width: 200px;

	img {
			width: 100%;
			height: auto;
			border: 1px solid #ddd;
			padding: 4px;
		    
		    @media (max-width: @screen-xs-max) { 
				
			}
	}

	@media (max-width: @screen-xs-max) { 
			width: 100px;
			height: auto;
	}
}
.persona__loader {
	text-align: center;
	padding: 20px;
}
.brand-secondary {
	color: @brand-secondary;
}